import React from "react"
import styled from "@emotion/styled"
import { ReactComponent as GCIcon } from "../../assets/givecloud-logo-color.svg"

const StyledGCIcon = styled(GCIcon)({
  marginTop: "0",
})

export default function Logo() {
  return <StyledGCIcon />
}
