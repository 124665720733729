// src/gatsby-theme-apollo-core/utils/colors.js
const { colors } = require("gatsby-theme-apollo-core/src/utils/colors")

const gc_colors = {
  // main
  blue: "#3666ff",
  cyan: "#42cffc",
  indigo: "#6c87f0",
  pink: "#fc58af",
  // others
  black: "#0b1b28",
  blue_dark: "#31708f",
  green: "#5cb85c",
  gray: "#f4f5f7",
  orange: "#feecdc",
  red: "#c81e1e",
}

exports.colors = {
  ...colors,
  primary: gc_colors.blue,
  primaryLight: gc_colors.cyan,
  secondary: gc_colors.pink,
  divider: gc_colors.gray,
}
